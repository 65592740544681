// extracted by mini-css-extract-plugin
export var bottomText = "InformationSection-module--bottomText--uDkn8";
export var contentWrapper = "InformationSection-module--contentWrapper--lzLch";
export var galleryImage = "InformationSection-module--galleryImage--Z7znq";
export var galleryWrapper = "InformationSection-module--galleryWrapper--G-Akk";
export var heading = "InformationSection-module--heading--gMak+";
export var informationSectionWrapper = "InformationSection-module--informationSectionWrapper--vhZdE";
export var logoImage = "InformationSection-module--logoImage--Js8UB";
export var logosWrapper = "InformationSection-module--logosWrapper--qF5H5";
export var mainImage = "InformationSection-module--mainImage--QRkdE";
export var mainText = "InformationSection-module--mainText--A6FEE";