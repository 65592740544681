import React from "react"
import * as Style from "./SubNav.module.scss"
import { Link } from "gatsby"
import Button from "../UI/custom-button/CustomButton"

const SubNav = ({ links, buttons, currentFilter, setCurrentFilter }) => {
  const filterHandler = controller => {
    setCurrentFilter(controller)
  }

  let content

  if (links) {
    content = (
      <div className={`${Style.subNavMainWrapper} main-container`}>
        {links?.map(item => {
          return (
            <div key={item.id} className={Style.linkWrapper}>
              <Link activeStyle={{ color: "yellow" }} to={item.url}>
                {item.link_name}
              </Link>
            </div>
          )
        })}
      </div>
    )
  } else if (buttons) {
    content = (
      <div className={`${Style.subNavMainWrapper} main-container`}>
        {buttons?.map(item => {
          return (
            <div key={item.id}>
              <Button
                buttonHandler={filterHandler.bind(this, item.title)}
                style={`${Style.btn} ${
                  currentFilter === item.title && Style.btnActive
                }`}
              >
                {item.title}
              </Button>
            </div>
          )
        })}
        {/* <div>
          <Button
            buttonHandler={filterHandler.bind(this, "all")}
            style={`${Style.btn} ${currentFilter === "all" && Style.btnActive}`}
          >
            2023
          </Button>
        </div> */}
      </div>
    )
  }

  return content
}

export default SubNav
